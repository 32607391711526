import styled from 'styled-components'
import { Link } from 'gatsby'
import InViewAnimation from 'components/inViewAnimation'
import media from 'constants/breakpoints'

export const InViewContainer = styled(InViewAnimation)`
  margin-bottom: var(--xl);
  width: 100%;

  ${media.TABLET`
    // margin-bottom: calc(var(--xl) * 2);
    margin-bottom: 128px;
    margin-top: ${(props) => (props.shift ? '128px' : '0')};
  `}

  ${media.DESKTOP`
    // margin-bottom: 33.333vh;
  `}
`

export const StyledLink = styled(Link)`
  display: block;
  width: 100%;
  height: auto;
`

export const MobileImageContainer = styled.div`
  position: relative;
  overflow: hidden;
  border-radius: ${(props) => (props.isRound ? '50%' : '0')};

  ${media.TABLET`
    display: none;
  `}
`

export const ImageContainer = styled.div`
  position: relative;
  height: 0px;
  overflow: hidden;
  border-radius: ${(props) => (props.isRound ? '50%' : '0')};
  // max-width: 454px;

  ${media.TABLET`
    height: auto;

    canvas {
      position: absolute;
      top: 0;
      left: 0;
      object-fit: cover;
      object-position: center center;
    }
  `}
`

export const Caption = styled.div`
  padding-left: 24px;
  padding-top: 4px;
  margin-top: 32px;
  position: relative;

  ${media.TABLET`
    margin-top: 40px;
  `}

  ::before,
  ::after {
    content: '';
    width: 1px;
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    height: 100%;
  }

  ::before {
    background: var(--neutral200);
  }

  ::after {
    background: var(--neutral500);
    transform: scale3d(0, 0, 1) translateZ(0);
    transition: transform 0.51s ease;
    transform-origin: top center;

    @media (hover: hover) {
      ${StyledLink}:hover & {
        transform: scaleX(1) translateZ(0);
      }
    }
  }
`

export const Copy = styled.span`
  display: inline-block;
  font-size: var(--text-sm);
  line-height: 1.6;
  font-weight: 300;
  color: var(--neutral700);
  margin-top: 8px;
  max-width: 320px;
`
