import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Layout from 'components/layout'
import { HomeHeader } from 'components/header'
import { HeaderButton } from 'components/header/headerButton'
import { MainHome } from 'components/main'
import About from 'components/about'
import Intro from 'components/intro'
import Container from 'components/container'
import TwoColumn from 'components/twoColumn'
import Project from 'components/project'
import ProjectDuo from 'components/projectDuo'
// import Footer from 'components/footer'

import { graphql } from 'gatsby'

const IndexPage = ({ data, location }) => {
  const [showAbout, setShowAbout] = useState(false)

  // Disables scrolling when the overlay is open
  useEffect(() => {
    if (typeof document !== undefined) {
      if (showAbout) {
        document.body.style.overflow = 'hidden'
        document.documentElement.style.overflow = 'hidden'
      } else {
        document.body.style.overflow = null
        document.documentElement.style.overflow = null
      }
    }
    return () => {
      document.body.style.overflow = null
      document.documentElement.style.overflow = null
    }
  }, [showAbout])

  // Close overlay on key press
  const escFunction = useCallback((event) => {
    if (event.keyCode === 27) {
      setShowAbout(false)
    }
  }, [])

  useEffect(() => {
    document.addEventListener('keydown', escFunction)
    return () => document.removeEventListener('keydown', escFunction)
  }, [escFunction])

  return (
    <Layout location={location}>
      <HomeHeader>
        <HeaderButton label={showAbout ? 'Close' : 'About'} onClick={() => setShowAbout(!showAbout)} />
      </HomeHeader>
      <MainHome>
        <About className={showAbout ? 'active' : null} />
        <Container as="section">
          <TwoColumn>
            <div>
              <Intro onClick={() => setShowAbout(!showAbout)} />
              {[data.homeJson.features[0]].map((feature, i) => (
                <Project {...feature} key={i} />
              ))}
            </div>

            {[data.homeJson.features[1]].map((feature, i) => (
              <Project {...feature} key={i} />
            ))}
          </TwoColumn>

          {[data.homeJson.features[2]].map((feature, i) => (
            <ProjectDuo {...feature} key={i} />
          ))}

          <TwoColumn>
            {[data.homeJson.features[3], data.homeJson.features[4]].map((feature, i) => (
              <Project {...feature} key={i} />
            ))}
          </TwoColumn>

          {[data.homeJson.features[5]].map((feature, i) => (
            <ProjectDuo {...feature} key={i} />
          ))}
        </Container>
      </MainHome>
      {/* <Footer /> */}
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
}

export default IndexPage

export const query = graphql`
  query HomepageQuery {
    homeJson {
      features {
        title
        copy
        comingSoon
        link
        mobileImage {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, width: 750, placeholder: BLURRED, quality: 100, formats: [AUTO, WEBP])
          }
        }
        desktopImage {
          publicURL
        }
        duoImage {
          publicURL
        }
        displacementImg {
          publicURL
        }
        imageRatio {
          width
          height
        }
        duoImageRatio {
          width
          height
        }
        shift
        flip
      }
    }
  }
`
