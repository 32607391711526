import React from 'react'
import PropTypes from 'prop-types'
// import Container from 'components/container'
import { FlexContainer } from './twoColumn.css'

const TwoColumn = ({ children }) => <FlexContainer>{children}</FlexContainer>

TwoColumn.propTypes = {
  children: PropTypes.node.isRequired
}

export default TwoColumn
