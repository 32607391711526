import React from 'react'
import PropTypes from 'prop-types'
import { Title } from 'components/typography'
import MobileImage from './mobileImage'
import DesktopImage from './desktopImage'
import InViewAnimation from 'components/inViewAnimation'

// import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import { DuoImage, InViewContainer, StyledLink, Caption, Copy } from './projectDuo.css'

const ProjectDuo = ({
  title,
  copy,
  mobileImage,
  desktopImage,
  duoImage,
  displacementImg,
  imageRatio,
  duoImageRatio,
  link,
  shift = false,
  flip = 'false'
}) => {
  // const image = getImage(duoImage)

  return (
    <InViewContainer shift={shift}>
      <StyledLink to={link} flip={flip}>
        <div>
          <MobileImage mobileImage={mobileImage} title={title} />
          <DesktopImage
            desktopImage={desktopImage}
            title={title}
            displacementImg={displacementImg}
            imageRatio={imageRatio}
          />
          <Caption>
            <Title as="h2" size="feature">
              {title}
            </Title>
            <Copy>{copy}</Copy>
          </Caption>
        </div>
        <InViewAnimation>
          <DuoImage flip={flip}>
            <DesktopImage
              desktopImage={duoImage}
              title={title}
              displacementImg={displacementImg}
              imageRatio={duoImageRatio}
            />
          </DuoImage>
        </InViewAnimation>
      </StyledLink>
    </InViewContainer>
  )
}

ProjectDuo.propTypes = {
  title: PropTypes.string.isRequired,
  copy: PropTypes.string.isRequired,
  desktopImage: PropTypes.object.isRequired,
  mobileImage: PropTypes.object.isRequired,
  displacementImg: PropTypes.object.isRequired,
  imageRatio: PropTypes.object.isRequired,
  link: PropTypes.string.isRequired,
  shift: PropTypes.bool,
  flip: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
}

export default ProjectDuo
