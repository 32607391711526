import React from 'react'
import PropTypes from 'prop-types'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { MobileImageContainer } from '../projectDuo.css'

const Image = ({ mobileImage, title }) => {
  const image = getImage(mobileImage)

  return (
    <MobileImageContainer>
      <GatsbyImage image={image} alt={title} />
    </MobileImageContainer>
  )
}

Image.propTypes = {
  mobileImage: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired
}

export default Image
