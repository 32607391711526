import styled from 'styled-components'
import media from 'constants/breakpoints'

export const Section = styled.section`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: var(--neutral50);
  z-index: 10;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;

  align-content: center;
  padding: 168px 0 98px;

  transform: translate3d(0, -100%, 0);
  transition: transform 0.51s cubic-bezier(0.23, 1, 0.32, 1), background 0s linear 0.51s;

  &.active {
    background: var(--bgsecondary);
    transform: translate3d(0, 0, 0);
    transition: transform 0.71s cubic-bezier(0.23, 1, 0.32, 1);
  }

  ${media.TABLET`
    padding: 168px 0 140px;
  `}
`

export const Container = styled.div`
  margin: auto;
  width: 80vw;
  max-width: 896px;

  p {
    margin-bottom: 24px;

    opacity: 0;
    transform: translate3d(0, -10vh, 0);

    ${Section}.active & {
      opacity: 1;
      transform: translateZ(0);
      transition: transform 0.91s cubic-bezier(0.165, 0.84, 0.44, 1), opacity 0.91s ease;
      transition-delay: 0.2s;

      &:nth-child(3) {
        transition-delay: 0.3s;
      }
      &:nth-child(2) {
        transition-delay: 0.4s;
      }
      &:nth-child(1) {
        transition-delay: 0.5s;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
`

export const ExternalLink = styled.a`
  position: relative;
  text-decoration: none;

  ::after {
    content: '';
    display: block;
    position: absolute;
    top: calc(1.5em + 1px);
    width: 100%;
    height: 1px;
    background: var(--neutral900);
    transform: scaleX(1) translateZ(0);
    transition: transform 0.31s cubic-bezier(0.165, 0.84, 0.44, 1);
    transform-origin: left;
  }

  &:hover::after {
    transform: scaleX(0) translateZ(0);
    transform-origin: right;
  }
`

export const StrikeThrough = styled.span`
  background-image: linear-gradient(to right, var(--struck) 100%, var(--struck) 0);
  background-size: 1px 1px;
  background-position: 0 calc(0.8em + 1px);
  background-repeat: repeat-x;
  color: var(--struck);
`

export const NoWrap = styled.span`
  white-space: nowrap;
`
