import React from 'react'
import PropTypes from 'prop-types'
import DistortionEffect from './distortionEffect'
import { ImageContainer } from '../projectDuo.css'

const Image = ({ desktopImage, displacementImg, imageRatio }) => (
  <ImageContainer>
    <DistortionEffect desktopImage={desktopImage} displacementImg={displacementImg} imageRatio={imageRatio} />
  </ImageContainer>
)

Image.propTypes = {
  // comingSoon: PropTypes.bool.isRequired,
  desktopImage: PropTypes.object.isRequired,
  displacementImg: PropTypes.object.isRequired,
  imageRatio: PropTypes.objectOf(PropTypes.number).isRequired
  // title: PropTypes.string.isRequired
}

export default Image
