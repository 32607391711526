import React from 'react'
import PropTypes from 'prop-types'
import { Title } from 'components/typography'

import MobileImage from './mobileImage'
import DesktopImage from './desktopImage'

import { InViewContainer, StyledLink, Caption, Copy } from './project.css'

const Project = ({
  title,
  copy,
  mobileImage,
  desktopImage,
  displacementImg,
  imageRatio,
  link,
  shift = false,
  isRound = false
}) => {
  return (
    <InViewContainer shift={shift}>
      <StyledLink to={link}>
        <MobileImage mobileImage={mobileImage} title={title} isRound={isRound} />
        <DesktopImage
          desktopImage={desktopImage}
          title={title}
          displacementImg={displacementImg}
          imageRatio={imageRatio}
          isRound={isRound}
        />
        <Caption>
          <Title as="h2" size="feature">
            {title}
          </Title>
          <Copy>{copy}</Copy>
        </Caption>
      </StyledLink>
    </InViewContainer>
  )
}

Project.propTypes = {
  title: PropTypes.string.isRequired,
  copy: PropTypes.string.isRequired,
  desktopImage: PropTypes.object.isRequired,
  mobileImage: PropTypes.object.isRequired,
  displacementImg: PropTypes.object.isRequired,
  imageRatio: PropTypes.object.isRequired,
  link: PropTypes.string.isRequired,
  shift: PropTypes.bool,
  isRound: PropTypes.bool
}

export default Project
