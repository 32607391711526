import styled from 'styled-components'
import InViewAnimation from 'components/inViewAnimation'
import media from 'constants/breakpoints'

export const InViewContainer = styled(InViewAnimation)`
  width: 90%;
  margin-bottom: 96px;

  ${media.TABLET`
  margin-bottom: 128px;
  `}
`
export const Copy = styled.p`
  font-family: var(--serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.2;

  font-size: 38px;

  ${media.TABLET`
    font-size: 40px;
  `}

  span {
    font-style: italic;
    white-space: nowrap;
  }
`

export const Button = styled.button`
  width: auto;
  // height: 36px;

  font-family: var(--serif);
  font-weight: 400;
  line-height: 1.2;
  font-style: italic;
  white-space: nowrap;

  font-size: 38px;

  ${media.TABLET`
    font-size: 40px;
  `}

  box-shadow: var(--orange) 0px -2px 0px inset;

  outline: 0;
  display: inline-block;
  padding: 0 1px;
  margin: 0 0 4px 0;
  border: 0;
  border-radius: 0;
  background-color: rgba(0, 0, 0, 0);
  color: var(--neutral900);
  pointer-events: all;
  cursor: pointer;

  transition: box-shadow 0.15s cubic-bezier(0.33, 0.66, 0.66, 1) 0s;

  @media (hover: hover) {
    &:hover {
      box-shadow: var(--orange) 0px -48px 0px inset;
    }
  }
`

export const NoWrap = styled.span`
  white-space: nowrap;
`
