import styled from 'styled-components'
import media from 'constants/breakpoints'

export const FlexContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;

  ${media.TABLET`
    flex-direction: row;

    > div {
      width: 50%;

      &:nth-child(odd) {
        margin-right: 6%;
        margin-left: 0;
      }

      &:nth-child(even) {
        margin-right: 0;
        margin-left: 6%;
      }
    }
  `}
`
