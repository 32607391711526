import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import hoverEffect from 'hover-effect'
// import Tilt from 'react-parallax-tilt'

const DistortionEffect = ({ desktopImage, displacementImg, imageRatio }) => {
  const canvasContainerRef = useRef()

  useEffect(() => {
    const element = canvasContainerRef.current
    if (!element) return

    new hoverEffect({
      parent: element,
      intensity: -0.5,
      speedIn: 1.8,
      speedOut: 1.8,
      imagesRatio: imageRatio.height / imageRatio.width,
      image1: desktopImage.publicURL,
      image2: desktopImage.publicURL,
      displacementImage: displacementImg.publicURL,
      easing: 'Expo.easeOut'
    })
  }, [canvasContainerRef, desktopImage, imageRatio, displacementImg])

  return (
    // <Tilt tiltReverse={true} tiltMaxAngleX={3} tiltMaxAngleY={3} transitionSpeed={2800}>
    <span
      ref={canvasContainerRef}
      style={{
        display: 'block',
        paddingBottom: (imageRatio.height / imageRatio.width) * 100 + '%'
      }}
    />
    // </Tilt>
  )
}

DistortionEffect.propTypes = {
  desktopImage: PropTypes.object.isRequired,
  displacementImg: PropTypes.object.isRequired,
  imageRatio: PropTypes.objectOf(PropTypes.number).isRequired
}

export default DistortionEffect
