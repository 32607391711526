import React from 'react'
import PropTypes from 'prop-types'
import { Copy } from 'components/typography'
import {
  Section,
  Container,
  // StrikeThrough,
  // NoWrap,
  ExternalLink
} from './about.css'

const About = ({ className }) => (
  <Section className={className}>
    <Container>
      <Copy as="p" size="level1" weight="light">
        Hey, I’m Mat, an experienced digital product designer specialising in UI, UX & interaction design. I mostly
        design websites & digital products for small start-ups & established brands.
      </Copy>

      {/* <Copy as="p" size="level1" weight="light">
        Hey, I’m Mat, an experienced digital designer specialising in product, UX & UI. Currently living in{' '}
        <StrikeThrough>London</StrikeThrough> <NoWrap>Hôi An, VN</NoWrap>. I mostly design websites & digital products
        for small start-ups & established brands.
      </Copy> */}

      <Copy as="p" size="level1" weight="light">
        Want to connect? Drop me an{' '}
        <ExternalLink href="mailto:hello@matbrewer.io?subject=Hey, let's chat!">email</ExternalLink> or add me on{' '}
        <ExternalLink href="https://www.linkedin.com/in/matbrewer" target="_blank" rel="noopener noreferrer">
          linkedin
        </ExternalLink>{' '}
        to get in touch & say hi.
      </Copy>
      <Copy as="p" size="level1" weight="light">
        <span role="img" aria-label="goodbye">
          👋
        </span>{' '}
        <span role="img" aria-label="call me">
          🤙
        </span>
      </Copy>
      <Copy as="p" size="medium" weight="light">
        P.S. I also run a{' '}
        <Copy size="medium" weight="light" fstyle="italic">
          small
        </Copy>{' '}
        <ExternalLink href="https://www.m3-design.co.uk" target="_blank" rel="noopener noreferrer">
          design studio
        </ExternalLink>
        .
      </Copy>
    </Container>
  </Section>
)

export default About

About.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func
}
