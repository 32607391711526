import React from 'react'
import PropTypes from 'prop-types'
import { InViewContainer, Button, Copy, NoWrap } from './intro.css'

const Intro = ({ onClick }) => (
  <InViewContainer>
    <Copy>
      A collection of recent works by digital designer{' '}
      <NoWrap>
        <Button onClick={onClick} type="button">
          Mat Brewer
        </Button>
        .
      </NoWrap>
    </Copy>
  </InViewContainer>
)

export default Intro

Intro.propTypes = {
  onClick: PropTypes.func.isRequired
}
